const proxy = "https://proxy-sites.herokuapp.com/";

const WS = "0x0000000000000000000000000000000000000000";//0x0000000000000000000000000000000000000000 recibe los huerfanos por defecto

var INFINITY = "0x50E21FE9B8446972f97Bf8E06b23c1CC7a703CcD"; // version 2

var TOKEN = "0x55d398326f99059fF775485246999027B3197955";
var chainId = '0x38';

var TESTNET = false // testnet comand

if(TESTNET){
    INFINITY = "0x50E21FE9B8446972f97Bf8E06b23c1CC7a703CcD"; // version 2  0xf47fBd34E663D8F5ad78fbf46Ae097202557A8e8

    TOKEN = "0xd5881b890b443be0c609BDFAdE3D8cE886cF9BAc";
    chainId = '0x61';// bnb testnet  || BTTChain "0xC7"//;
}

export default {proxy, WS,INFINITY, TOKEN, chainId};
