import React, { Component } from "react";
import cons from "../../cons.js";
const BigNumber = require('bignumber.js');


export default class CrowdFunding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      min: 100,
      deposito: "Loading...",
      balance: "Loading...",
      accountAddress: "Loading...",
      porcentaje: "Loading...",
      dias: "Loading...",
      partner: "Loading...",
      balanceTRX: "Loading...",
      balanceUSDT: "Loading...",
      precioSITE: 1,
      valueUSDT: 0,
      hand: 0,
      cantidadBlokes: 1,
      valorBlokes: 30,
      valorBloke: 30,
      tiempo: 0,
      estadoBuy: "Loading...",
      buyMembership: "$10/YEAR",
      datos: [
        {
            "blks": 0,
            "team": 0,
            "refer": 0,
            "pasive": 0
        },
        {
            "blks": 0,
            "team": 0,
            "refer": 0,
            "pasive": 0
        },
        {
            "blks": 0,
            "team": 0,
            "refer": 0,
            "pasive": 0
        }
      ],
      balanceInfinito: 0,
      multiNivel: [],
      rango: "N/A",
      textRango: "loading...",
      imageRango: "0",

    };

    this.deposit = this.deposit.bind(this);
    this.estado = this.estado.bind(this);
    this.estado2 = this.estado2.bind(this);

    this.hijos = this.hijos.bind(this);
    this.buscaren = this.buscaren.bind(this);

    this.handleChangeA = this.handleChangeA.bind(this);
    this.handleChangeB = this.handleChangeB.bind(this);

    this.rango = this.rango.bind(this);
    this.claim = this.claim.bind(this);

    
  }

  handleChangeA(event) {
    var evento = event.target.value;
    this.setState({
      cantidadBlokes: evento,
      valorBlokes: evento * this.state.valorBloke,
    });
  }

  handleChangeB(event) {
    var evento = event.target.value;
    this.setState({
      valorBlokes: evento,
      cantidadBlokes: evento / this.state.valorBloke,
    });
  }

  async componentDidMount() {
    
    this.setState({
      currentAccount: this.props.currentAccount,
    });
    setInterval(async() => {
      var verWallet = this.props.currentAccount;

      if(!this.props.wallet.web3.utils.isAddress(verWallet)){
        verWallet = await this.props.wallet.contractBinary.methods
        .idToAddress(verWallet)
        .call({ from: "0x0000000000000000000000000000000000000000" });
      }
      this.setState({
        currentAccount: verWallet,
      });
      this.estado();
      this.estado2();
      this.hijos();
      this.rango();
    }, 3 * 1000);
  }


  async buscaren(base, paso, hasta){

    for (let index = 0; index < base[paso].length; index++) {

      var columnHijos = await this.props.wallet.contractBinary.methods
      .columnHijos(base[paso][index])
      .call({ from: this.state.currentAccount });

      if(columnHijos === false){

      }else{
        base[paso+1] = [].concat(columnHijos,base[paso+1]);
      }
      
    }

    //console.log(base)

    paso++;

    if(paso < hasta ){
      return await this.buscaren(base, paso, hasta);

    }else{
      return base;
    }

  }

  async hijos() {

    var niveles = [[],[],[]]
    var porcentajes = [0.09, 0.06, 0.03]
    var iconos = ["fa-solid fa-user-large","fa-solid fa-user-group","fa-solid fa-people-group"]
    var datos = [
      {refer: 0, blks: 0, team: 0, pasive: 0},
      {refer: 0, blks: 0, team: 0, pasive: 0},
      {refer: 0, blks: 0, team: 0, pasive: 0},
      {refer: 0, blks: 0, team: 0, pasive: 0}
    ];

    var columnHijos = await this.props.wallet.contractBinary.methods
    .columnHijos(this.state.currentAccount)
    .call({ from: this.state.currentAccount });

    niveles[0] = columnHijos;

    var investor;

    niveles = await this.buscaren(niveles, 0, 2);

    for (let index = 0; index < niveles.length; index++) {
      for (let sub = 0; sub < niveles[index].length; sub++) {

        investor = await this.props.wallet.contractBinary.methods
        .investors( niveles[index][sub])
        .call({ from: this.state.currentAccount });

        var blokes = new BigNumber(investor.invested).shiftedBy(-18);

        datos[index].blks  += blokes.dividedBy(30).toNumber();
        datos[index].pasive  += blokes.toNumber();
        datos[index].refer  += blokes.multipliedBy(porcentajes[index]).toNumber();

      }

      datos[index].team = parseInt(niveles[index].length);

    }

    var multiNivel = []

    for (let index = 0; index < niveles.length; index++){

      datos[niveles.length].team += datos[index].team
      datos[niveles.length].blks += datos[index].blks
      datos[niveles.length].pasive += datos[index].pasive
      datos[niveles.length].refer += datos[index].refer

      multiNivel[index] = (
        <li className="list-group-item px-0" key={"mlevels-"+index}>
          <div className="row align-items-center">
            <div className="col ml-2">
              <span className="badge badge-success badge-sm">Level {index+1}</span> 
              
            </div>

            <div className="col ml-1">
              
              <i className={iconos[index]}></i> {" "}
              {datos[index].team}
              
            </div>
            <div className="col ml-2">
              {datos[index].blks} TESLA
            </div>

            <div className="col-auto">
              | ${datos[index].pasive} 
            </div>

            <div className="col-auto">
              {"->"} ${datos[index].refer} 
            </div>
            
          </div>
        </li>
      );
    }

    if(niveles.length > 0){
      multiNivel[niveles.length] = (
        <li className="list-group-item px-0" key={"mlevels-"+niveles.length}>
          <div className="row align-items-center">
            <div className="col ml-2">
              <span className="badge badge-info badge-sm">TOTAL</span> 
              
            </div>

            <div className="col ml-1">
              <i className="fa-solid fa-people-roof"></i> {" "}
              {datos[niveles.length].team}
            </div>
            <div className="col ml-2">
              {datos[niveles.length].blks} TESLA
            </div>

            <div className="col-auto">
              | ${datos[niveles.length].pasive} 
            </div>

            <div className="col-auto">
              {"->"} ${datos[niveles.length].refer} 
            </div>
            
          </div>
        </li>
      );

    }

    this.setState({
      datos: datos,
      multiNivel: multiNivel
    })

  }

  async estado() {
    var inversors = await this.props.wallet.contractBinary.methods
      .investors(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    var tiempo = ((inversors.membership - Date.now() / 1000) / 86400).toFixed(
      0
    );
    var buyMembership = "$10/YEAR";

    if (tiempo <= 0) {
      tiempo = " Please buy a status CLUB TESLA";
    } else {
      tiempo = tiempo + " days left";
      buyMembership = "CLUB TESLA";
    }

    this.setState({
      tiempo: tiempo,
      buyMembership: buyMembership,
    });
  }

  async estado2() {
    var accountAddress = this.state.currentAccount;
    var inversors = await this.props.wallet.contractBinary.methods
      .investors(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

  
    var nameToken1 = await this.props.wallet.contractToken.methods
      .symbol()
      .call({ from: this.state.currentAccount });

    var aprovado = await this.props.wallet.contractToken.methods
      .allowance(accountAddress, this.props.contractAddress)
      .call({ from: this.state.currentAccount });

    if (aprovado > 0) {
      if (!inversors.registered) {
        aprovado = "CLUB TESLA";
      } else {
        aprovado = "Buy";
      }
    } else {
      aprovado = "Allow";
    }

    inversors.inicio = 1000;

    var tiempo = await this.props.wallet.contractBinary.methods
      .tiempo()
      .call({ from: this.state.currentAccount });

    tiempo = tiempo * 1000;

    var porcentiempo = ((Date.now() - inversors.inicio) * 100) / tiempo;

    var decimales = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var balance = await this.props.wallet.contractToken.methods
      .balanceOf(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    balance = balance / 10 ** decimales;

    var valorPlan = 0;

    if (porcentiempo < 100) {
      aprovado = "Update Plan";

      valorPlan = inversors.plan / 10 ** 8;
    }

    var partner = cons.WS;

    if (inversors.registered) {
      partner = await this.props.wallet.contractBinary.methods
        .padre(this.state.currentAccount)
        .call({ from: this.state.currentAccount });
    } else {
      var loc = document.location.href;
      if (loc.indexOf("?") > 0) {
        var getString = loc.split("?");
        //console.log(getString)
        getString = getString[getString.length - 1];
        //console.log(getString);
        var GET = getString.split("&");
        var get = {};
        for (var i = 0, l = GET.length; i < l; i++) {
          var tmp = GET[i].split("=");
          get[tmp[0]] = unescape(decodeURI(tmp[1]));
        }

        if (get["ref"]) {
          tmp = get["ref"].split("#");

          var wallet = tmp[0];
          if (!this.props.wallet.web3.utils.isAddress(wallet)) {
            wallet = await this.props.wallet.contractBinary.methods
            .idToAddress(tmp[0])
            .call({ from: this.state.currentAccount });
          }

          inversors = await this.props.wallet.contractBinary.methods
            .investors(wallet)
            .call({ from: this.state.currentAccount });
          //console.log(wallet);
          if (inversors.registered) {
            partner = wallet;
          }
        }
      }
    }

    if (partner === "0x0000000000000000000000000000000000000000") {
      partner = "---------------------------------";
    }

    var dias = 365; //await Utils.contract.tiempo().call();

    var porcentaje = await this.props.wallet.contractBinary.methods
      .porcent()
      .call({ from: this.state.currentAccount });

    porcentaje = parseInt(porcentaje);

    var decimals = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var balanceUSDT = await this.props.wallet.contractToken.methods
      .balanceOf(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    balanceUSDT = new BigNumber(balanceUSDT).shiftedBy(-decimals).decimalPlaces(2).toString(10);

    this.setState({
      deposito: aprovado,
      balance: valorPlan,
      decimales: decimales,
      accountAddress: accountAddress,
      porcentaje: porcentaje,
      dias: dias,
      partner: partner,
      balanceSite: balance,
      balanceUSDT: balanceUSDT,
      nameToken1: nameToken1,
      balanceInfinito: new BigNumber(inversors.balanceInfinit).shiftedBy(-18).toNumber()
    });
  }

  async deposit() {
    var { balanceSite, balance } = this.state;

    var aprovado = await this.props.wallet.contractToken.methods
      .allowance(this.state.currentAccount, this.props.contractAddress)
      .call({ from: this.state.currentAccount });

    if (aprovado <= 0) {
      await this.props.wallet.contractToken.methods
        .approve(
          this.props.contractAddress,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        )
        .send({ from: this.state.currentAccount });
      window.alert("Balance approval for exchange: successful");
      return;
    }
    var blokes = document.getElementById("b").value;
    var amount = blokes;
    amount = amount - balance;

    if (aprovado > 0 && balanceSite >= amount) {
      var loc = document.location.href;
      var sponsor = cons.WS;
      var investors = await this.props.wallet.contractBinary.methods
        .investors(this.state.currentAccount)
        .call({ from: this.state.currentAccount });

      if (investors.registered) {
        sponsor = await this.props.wallet.contractBinary.methods
          .padre(this.state.currentAccount)
          .call({ from: this.state.currentAccount });
      } else {
        if (loc.indexOf("?") > 0) {
          var getString = loc.split("?");
          getString = getString[getString.length - 1];
          //console.log(getString);
          var GET = getString.split("&");
          var get = {};
          for (var i = 0, l = GET.length; i < l; i++) {
            var tmp = GET[i].split("=");
            get[tmp[0]] = unescape(decodeURI(tmp[1]));
          }

          if (get["ref"]) {
            tmp = get["ref"].split("#");

            var wallet = await this.props.wallet.contractBinary.methods
              .idToAddress(tmp[0])
              .call({ from: this.state.currentAccount });

            var padre = await this.props.wallet.contractBinary.methods
              .investors(wallet)
              .call({ from: this.state.currentAccount });

            if (padre.registered) {
              sponsor = wallet;
            }
          }
        }
      }

      if (
        !investors.registered &&
        sponsor !== "0x0000000000000000000000000000000000000000"
      ) {

        this.props.wallet.contractBinary.methods.registro(sponsor)
        .send({ from: this.state.currentAccount })
        .then(() => window.alert("Congratulations your registration is successful"));
        return;
      } else {
        if (!investors.registered) {
          alert("you need a referral link to register");
          return;
        }
      }

      blokes = new BigNumber(blokes).shiftedBy(18);

      this.props.wallet.contractBinary.methods
        .buyBlocks(blokes.toString(10))
        .send({ from: this.state.currentAccount })
        .then(() => {
          window.alert("Congratulations, you received your Tesla!");
        });

    } else {
      if (balanceSite < amount) {
        window.alert(
          "You do not have enough balance, you need: " +
            amount +
            " USDT and in your wallet you have: " +
            balanceSite
        );
      }
    }
  }

  async rango() {
    var investor = await this.props.wallet.contractBinary.methods
    .investors( this.state.currentAccount )
    .call({ from: this.state.currentAccount });

    //console.log(investor)

    var rango = new BigNumber(investor.blokesDirectos).shiftedBy(-18).dividedBy(30).toNumber(10);
    var rangoArray = [];
    var rangoEstilo = "secondary";
    var gananciasRango = "Claimed";
    var funcionRango = () => {};
    var cantidad = "";
    var imageRango = 0;
    var netxRango = [0, 10, 20, 30, 100, 200, 300, 1000, 2000, 3000, 10000, 20000, 30000];
    var nameRango = ["","Energy","Frequency","Vibration","Bronze","Silver",
    "Gold","Sapphire","Ruby","Emerauld",
    "Diamond","Supreme Diamond","King/Queen Diamond" ]

    var textRango = "Next Rank "

    if(investor.registered){

      for (let index = 0; index < netxRango.length-1; index++) {
        rangoArray[index] = await this.props.wallet.contractBinary.methods
          .rangoReclamado(this.state.currentAccount, index)
          .call({ from: this.state.currentAccount });
      }

      var progreso = 0;

      if (rango >= netxRango[0] && rango < netxRango[1]) {
        textRango += rango+"/"+netxRango[1] + " TESLA";
        progreso = 100*rango/netxRango[1]
        rango = nameRango[0];
        imageRango = 0;
        
      }else{

        if (rango >= netxRango[netxRango.length-1] ) {
          textRango = "Welcome to the PARADISE!!!";
          progreso = 100
          rango = nameRango[nameRango.length-1];
          if (!rangoArray[nameRango.length-2]) {
            rangoEstilo = "success";
            cantidad = await this.props.wallet.contractBinary.methods
              .gananciasRango(nameRango.length-2)
              .call({ from: this.state.currentAccount });
            cantidad = cantidad / 10 ** 18;
            gananciasRango = `Claim ${cantidad} USDT`;
            funcionRango = () => {
              return this.claim();
            };
          }
          imageRango = nameRango.length-1;
        }else{
          for (let index = 1; index < netxRango.length; index++) {
          
            if (rango >= netxRango[index] && rango < netxRango[index+1]) {
              textRango += rango+"/"+netxRango[index+1] + " TESLA";
              progreso = 100*rango/netxRango[index+1]
              rango = nameRango[index];
              if (!rangoArray[index-1]) {
                rangoEstilo = "success";
                cantidad = await this.props.wallet.contractBinary.methods
                  .gananciasRango(index-1)
                  .call({ from: this.state.currentAccount });
                cantidad = cantidad / 10 ** 18;
                gananciasRango = `Claim ${cantidad} USDT`;
                funcionRango = () => {
                  return this.claim();
                };
              }
              imageRango = index;
            }
          }

        }

      }
    }


    this.setState({
      rango: rango,
      rangoEstilo: rangoEstilo,
      progreso: progreso,
      textRango: textRango,
      gananciasRango: gananciasRango,
      funcionRango: funcionRango,
      imageRango: imageRango,
    });
  }
  async claim() {
    await this.props.wallet.contractBinary.methods
      .newRecompensa()
      .send({ from: this.state.currentAccount });
  }

  render() {
    return (
      <>

        <div className="row">

          <div className="col-lg-6 col-md-6 col-12">
          <div className="numbers">
                <div className="card  mb-4">
                  <div className="card-body p-3">
                    <div className="row">

                      <div className="col-10">
                          <p className="text-sm mb-0 text-uppercase font-weight-bold">MY WALLET</p>
                          <p className="mb-0">
                          <b className="text-white">{this.state.currentAccount} </b><br />
                          
                           USDT: {this.state.balanceUSDT} <br />
                          Upline:  <b>{this.state.partner}</b> <br />
                          </p>
                          <span className="font-weight-bolder text-light" >BENEFITS: {this.state.tiempo}</span>
                          <button
                            className="btn btn-sm btn-white bg-gradient-primary mb-0 w-100"
                            onClick={async () => {
                              var aprovado = await this.props.wallet.contractToken.methods
                                .allowance(this.state.currentAccount, this.props.contractAddress)
                                .call({ from: this.state.currentAccount });

                              if (aprovado <= 0) {
                                await this.props.wallet.contractToken.methods
                                  .approve(
                                    this.props.contractAddress,
                                    "115792089237316195423570985008687907853269984665640564039457584007913129639935"
                                  )
                                  .send({ from: this.state.currentAccount });
                                window.alert("Balance approval for exchange: successful");
                                return;
                              }

                              var loc = document.location.href;
                              var sponsor = cons.WS;
                              var investors =
                                await this.props.wallet.contractBinary.methods
                                  .investors(this.state.currentAccount)
                                  .call({ from: this.state.currentAccount });

                              if (investors.registered) {
                                sponsor =
                                  await this.props.wallet.contractBinary.methods
                                    .padre(this.state.currentAccount)
                                    .call({ from: this.state.currentAccount });
                              } else {
                                if (loc.indexOf("?") > 0) {
                                  var getString = loc.split("?");
                                  getString = getString[getString.length - 1];
                                  //console.log(getString);
                                  var GET = getString.split("&");
                                  var get = {};
                                  for (var i = 0, l = GET.length; i < l; i++) {
                                    var tmp = GET[i].split("=");
                                    get[tmp[0]] = unescape(decodeURI(tmp[1]));
                                  }

                                  if (get["ref"]) {
                                    tmp = get["ref"].split("#");

                                    var wallet =
                                      await this.props.wallet.contractBinary.methods
                                        .idToAddress(tmp[0])
                                        .call({ from: this.state.currentAccount });

                                    var padre =
                                      await this.props.wallet.contractBinary.methods
                                        .investors(wallet)
                                        .call({ from: this.state.currentAccount });

                                    if (padre.registered) {
                                      sponsor = wallet;
                                    }
                                  }
                                }
                              }
                            if(sponsor !== "0x0000000000000000000000000000000000000000" ){

                              this.props.wallet.contractBinary.methods
                                .registro(sponsor, "")
                                .send({ from: this.state.currentAccount })
                                .then(() => {
                                  window.alert("Status CLUB buyed");
                                });
                            }else{
                              window.alert("You need a invitation link");
                            }
                            }}
                            
                          >
                            {this.state.buyMembership}
                          </button>
                        </div> 
                        <div className="col-2 text-end">
                          <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle"> <i className="fa fa-wallet text-lg opacity-10" aria-hidden="true"></i> </div>

                        </div>

                          

                    </div>
                        
                          
                    </div>
                  </div>
                </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="card  mb-4">
              <div className="card-body p-3">
                <div className="row">
                  <div className="col-8">
                    <div className="numbers">
                      <h5 className="font-weight-bolder">

                      <input
                        id="a"
                        type="number"
                        className="center-align white-text"
                        style={{width:"70%"}}
                        value={this.state.cantidadBlokes}
                        onChange={this.handleChangeA}
                        step={1}
                      /> TESLA<br></br>
                        x <br></br>
                        <input
                        id="b"
                        type="number"
                        className="center-align white-text"
                        style={{width:"70%"}}
                        value={this.state.valorBlokes}
                        onChange={this.handleChangeB}
                        step={this.state.valorBloke}
                      /> USD
                        
                        
                    </h5>
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle"> <img src="imagenes/tesla-icon.png" height="45px" alt=""></img> </div>
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-white bg-gradient-primary mb-0 w-100"
                  onClick={() => this.deposit()}
                >
                  {this.state.deposito} {this.state.cantidadBlokes} TESLA = $ {this.state.valorBlokes}
                </button>
              </div>
            </div>
          </div>
        
        </div>
        
        <div className="row mb-4">
          <div className="col-12 col-lg-12">

            <div className="card h-100 ">
              <div className="card-header">
                <h5 className="mb-0 text-capitalize">My team</h5> </div>
              <div className="card-body pt-0">
                <ul className="list-group list-group-flush">
                  
                  {this.state.multiNivel}


                </ul>
              </div>
            </div>

          </div>
        </div>

        <div className="row mb-4">

          <div className="col-12 col-lg-12">
        
            <div className="card h-100 ">
              <div className="card-body pt-0">
                <ul className="list-group list-group-flush list">
                  <li className="list-group-item px-0">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <img alt="rango usuario" src={"rangos/"+this.state.imageRango+".png"} height="120px" /> 
                      </div>
                      <div className="col">
                        <h6 className="mt-3">RANK: {this.state.rango} || {this.state.textRango}</h6>
                        <div className="progress progress-xs mb-3">
                          <div className="progress-bar bg-info" role="progressbar"  aria-valuemin="0" aria-valuemax="100" style={{width: this.state.progreso+"%"}}></div>
                        </div>

                        <button
                          className={"btn btn-sm btn-white bg-gradient-"+this.state.rangoEstilo+" mb-0 w-100"}
                          onClick={()=>this.state.funcionRango()}
                        >
                          RECEIVE
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
        </div>

            
      </>
    );
  }
}
