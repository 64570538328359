import React, { Component } from "react";

import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';

import Home from "../V1Home";
import TronLinkGuide from "../TronLinkGuide";
import cons from "../../cons"

import abiToken from "../../token";
import abiInfinity from "../../infinity-abi";

var chainId = cons.chainId;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: false,
      metamask: false,
      conectado: false,
      currentAccount: "0x0000000000000000000000000000000000000000",
      binanceM:{
        web3: null,
        contractToken: null,
        contractBinary: null
      }
      
    };

    this.isAdress = this.isAdress.bind(this);
    
  }

  async componentDidMount() {

      if (typeof window.ethereum !== 'undefined') {        
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId}],
        });       
        
          //console.log(resultado[0]);
          this.setState({
            metamask: true,
            conectado: true
          })

      } else {          
        this.setState({
          metamask: false,
          conectado: false
        })      
      }

      setInterval(async() => {
        if (typeof window.ethereum !== 'undefined') {        
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId}],
          });

          var resultado = await window.ethereum.request({ method: 'eth_requestAccounts' });
          var isAdmin = await contractBinary.methods.admin(resultado[0]).call({from:this.state.currentAccount});

          var verWallet = resultado[0];
          var loc = document.location.href;
  
          if(loc.indexOf('?')>0 && loc.indexOf('view')>0){
                    
            verWallet = loc.split('?')[1];
            verWallet = verWallet.split('=')[1];
            verWallet = verWallet.split('#')[0];
  
            if(!web3.utils.isAddress(verWallet)){
              verWallet = await contractBinary.methods.idToAddress(verWallet).call({from:this.state.currentAccount});
            }
      
          }
            this.setState({
              currentAccount: verWallet,
              metamask: true,
              conectado: true,
              admin: isAdmin,
            })
  
        } else {          
          this.setState({
            metamask: false,
            conectado: false
          })      
        }

      },7*1000);


      try {         

        const provider = await detectEthereumProvider();// detect provider metamask

        var web3 = new Web3(provider);
        var contractToken = new web3.eth.Contract(
          abiToken,
          cons.TOKEN
        );
        var contractBinary = new web3.eth.Contract(
          abiInfinity,
          cons.INFINITY
  
        );
  
        
          this.setState({
            binanceM:{
              web3: web3,
              contractToken: contractToken,
              contractBinary: contractBinary
            }
          })
  
        //web3 = new Web3(new Web3.providers.HttpProvider("https://data-seed-prebsc-1-s1.binance.org:8545/"));
      } catch (error) {
          alert(error);
      } 


  }

  isAdress(wallet){

    if (!this.props.wallet.web3.utils.isAddress(wallet)) {
      this.props.wallet.contractBinary.methods
      .idToAddress(wallet)
      .call({ from: this.props.contractAddress })
      .then((address)=>{return address;})
      .catch(()=>{return wallet;})
    }

  }

  render() {

    if (!this.state.metamask) return (
      <>
        <div className="row">
          <TronLinkGuide />
        </div>
      </>
      );

    if (!this.state.conectado) return (
      <>
        <div className="row">
          <TronLinkGuide installed />
        </div>
      </>
      );

      var getString = "";
      var loc = document.location.href;
      
      if(loc.indexOf('?')>0){
                
        getString = loc.split('?')[1];
        getString = getString.split('#')[0];
        getString = getString.split('&')[0];
        getString = getString.split('=')[0];

  
      }
    
    switch (getString) {
      case "view":
        return(
          <div className="row">
            <Home admin={this.state.admin} contractAddress={cons.INFINITY} version="2" wallet={this.state.binanceM} currentAccount={this.state.currentAccount}/>
          </div>
        );
      default:
        return(
          <div className="row">
            <Home admin={this.state.admin} contractAddress={cons.INFINITY} version="2" wallet={this.state.binanceM} currentAccount={this.state.currentAccount}/>
          </div>
        );
    }

      
  
  }
}
export default App;